
import { defineComponent } from "vue";
import { data } from "@/shared/notfound";
import CliveLogoLinkMobile from "@/components/atoms/link/CliveLogoLinkMobile/CliveLogoLinkMobile.vue";
import AppRouterLink from "@/components/atoms/link/AppRouterLink/AppRouterLink.vue";
export default defineComponent({
  name: "NotFound",
  components: { CliveLogoLinkMobile, AppRouterLink },
  setup() {
    const { title, description } = data.mobile;
    return {
      title,
      description
    };
  }
});
