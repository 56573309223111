export const data = {
  title: "해당 페이지를 찾을 수 없습니다",
  description:
    "잘못된 접근이거나 요청하신 페이지를 찾을 수 없습니다.<br /> 입력하신 페이지의 주소가 정확한지 다시 한번 확인해 주시기 바랍니다. 불편을 드려 죄송합니다.",
  mobile: {
    title: "해당 페이지를<br> 찾을 수 없습니다",
    description:
      "잘못된 접근이거나 요청하신 페이지를 찾을 수 없습니다. 입력하신 페이지의 주소가 정확한지 다시 한번 확인해 주시기 바랍니다. 불편을 드려 죄송합니다."
  }
};
